import React, {Component} from "react"
import {
    DownIcon, FullscreenIcon,
    getIcon,
    MenuFoldIcon,
    NoticeLinkIcon,
    NoticeTextIcon,
    PasswordIcon,
    SignoutIcon,
    UserConfigIcon
} from "../../../utils/icon"
import { Button, Drawer, Dropdown, Empty, Layout, notification, Switch} from "antd"; //
import storageUtils from "../../../utils/storage";
import './index.css'
import {Link} from "react-router-dom";
import withRouter from "../../../utils/withRouter";


const {Header} = Layout;

class LayoutHead extends Component {

    autoPlayRef = React.createRef();
    formRef = React.createRef()
    state = {
        visible: false,
        noticeList: storageUtils.getKey('noticeList') || [],
        userStatus: false,
        noticeStatus: 1,
        xzqhList: [],
        title:''
    }

    news = () => {
        this.setState({
            visible: true,
        });
    }
    onClose = () => {
        this.setState({
            visible: false,
        });
    };
    array = (arr, linkUrl) => {
        for (let index = 0; index < arr.length; index++) {
            if (arr[index].url === linkUrl) {
                return true;
            }
            if (arr[index]._child) {
                if (this.array(arr[index]._child, linkUrl)) {
                    return true;
                }
            }
        }
        return false;
    }

    async componentDidMount() {
        setTimeout(()=>{
            this.setState({title:storageUtils.getConfig().title})
        },100)
        notification.config({
            placement: 'bottomRight',
        });

    }

    onNoticeStatusChange(checked, error) {
        this.setState({
            noticeStatus: checked ? 1 : 0
        })

        let user = storageUtils.getKey('user');
        if (user.config) {
            user.config.noticeStatus = checked ? 1 : 0
        } else {
            user.config = {noticeStatus: checked ? 1 : 0}
        }
        storageUtils.saveUser(user); //存储用户资料;
    }

    noticeBindClick = (index) => {
        let noticeList = this.state.noticeList
        const item = noticeList[index];
        if (item.type === 'url') {
            if (item.content.url) {
                if (this.props.history.location.pathname !== item.content.url) {
                    //this.props.history.push(item.content.url)
                } else {

                }
                this.props.history.push(item.content.url)
                this.props.history.push({pathname: "/public/empty"});

                this.props.selectedKey(item.power + '_' + new Date().getTime());

            }
        }
        noticeList.splice(index, 1)
        this.setState({noticeList: noticeList, visible: false,}, () => {
            storageUtils.setKey('noticeList', noticeList)
        })
    }

    getDateStr(stringTime) {
        let minute = 60000;
        let hour = minute * 60;
        let day = hour * 24;
        let week = day * 7;
        let month = day * 30;
        let time1 = new Date().getTime(); //当前的时间戳
        let time2 = Date.parse(new Date(stringTime)); //指定时间的时间戳
        let time = time1 - time2;

        let result = null;
        if (time < 0) {
            result = "--";
        } else if (time / month >= 1) {
            result = parseInt(time / month) + "月前";
        } else if (time / week >= 1) {
            result = parseInt(time / week) + "周前";
        } else if (time / day >= 1) {
            result = parseInt(time / day) + "天前";
        } else if (time / hour >= 1) {
            result = parseInt(time / hour) + "小时前";
        } else if (time / minute >= 1) {
            result = parseInt(time / minute) + "分钟前";
        } else {
            result = "刚刚";
        }
        return result;
    }

    confirm = () => {
        this.formRef.current.validateFields().then(() => {
            const values = this.formRef.current.getFieldsValue()
            storageUtils.saveXzqh(values.xzqh)
            let name = ''
            this.state.xzqhList.forEach((item) => {
                if (values.xzqh === item.xzqh) {
                    name = item.name
                }
            })
            storageUtils.saveXzqhName(name)
            this.setState({
                modalVisible: false
            })
            this.props.router.uNavigate('/')
        }).catch((e) => {

        })

    }
    fullScreenForDOM() {
        let sel = document.querySelector('.ant-layout-content')
        let rfs = sel.requestFullScreen || sel.webkitRequestFullScreen || sel.mozRequestFullScreen || sel.msRequestFullScreen, wscript;
        if (typeof rfs != "undefined" && rfs) {
            rfs.call(sel);
            return;
        }
        if (typeof window.ActiveXObject != "undefined") {
            wscript = new window.ActiveXObject("WScript.Shell");
            if (wscript) {
                wscript.SendKeys("{F11}");
            }
        }
    }
    render() {
        const items = [
            {
                label: (
                    <Link rel="noopener noreferrer" to={`/admin/organization/password`}>
                        <PasswordIcon/> &nbsp; 修改密码
                    </Link>
                ),
                key: '1',
            },
            {
                label: (
                    <Link rel="noopener noreferrer" to={`/public/login`}>
                        <SignoutIcon/> &nbsp; 账户退出
                    </Link>
                ),
                key: '2',
            },
        ]

        const userInfo = storageUtils.getUser();
        return (
            <Header style={{background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between',height:'4rem'}}>
                <audio ref={this.autoPlayRef} style={{visibility: "hidden"}} autoPlay={false}>
                    <source src="/notify.mp3" type="audio/mpeg"/>
                </audio>
                <div style={{width:'25%'}}>
                    <MenuFoldIcon className="trigger" onClick={() => {
                        this.props.setCollapsed(!this.props.collapsed)
                    }}/>
                </div>
                <div style={{fontWeight:'bold',width:'50%',textAlign:'center',fontSize:20}}>{this.state.title}</div>
                <div className="header" style={{width:'25%',display:'flex', justifyContent: 'right'}}>
                    <FullscreenIcon onClick={() => this.fullScreenForDOM()} style={{fontSize: 20,padding:6}}/>

                    <Dropdown className="headerSapn" menu={{items}}>
                        <div className='head-hover' style={{padding: '0 1.5em'}}>
                            <div style={{fontSize: 15}}>
                                {this.state.userStatus ? getIcon({
                                    style: {
                                        fontSize: 22,
                                        verticalAlign: '-0.25em',
                                        color: "#52c41a"
                                    }
                                }, 'tn-user') : getIcon({
                                    style: {
                                        fontSize: 22,
                                        verticalAlign: '-0.25em',
                                        color: "#eb2f96"
                                    }
                                }, 'tn-user')}
                                <samp
                                    style={{padding: '0px 3px 0 6px'}}>{userInfo.info.username}{userInfo.info.real_name ? '(' + userInfo.info.real_name + ')' : ''}</samp>
                                <DownIcon style={{fontSize: 10}}/>
                            </div>
                        </div>
                    </Dropdown>
                </div>
                <Drawer title="历史消息" placement='right' closable={true} onClose={() => this.onClose()}
                        open={this.state.visible} width={400}
                        footer={
                            <div style={{textAlign: 'left', height: '32px', lineHeight: '32px'}}>
                                {this.state.noticeList.length > -1 ? <Button type="primary" style={{float: 'right'}}
                                                                             onClick={() => this.setState({noticeList: []}, () => {
                                                                                 storageUtils.setKey('noticeList', this.state.noticeList)
                                                                             })}>清空全部消息</Button> : ''}
                                本次登录消息：<Switch onChange={(error) => this.onNoticeStatusChange(error, this)}
                                               defaultChecked={this.state.noticeStatus} checkedChildren="接受"
                                               unCheckedChildren="关闭"/>
                            </div>}
                >
                    <div style={{paddingBottom: '40px'}}>
                        {this.state.noticeList.length === 0 ? (
                            <Empty style={{marginTop: '250px'}} description='暂无消息'/>) : (
                            this.state.noticeList.map((item, index) => {
                                if (index < 50) {
                                    return (
                                        <div style={{
                                            cursor: 'pointer',
                                            borderBottom: '1px solid #eeeeee',
                                            padding: '12px 18px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }} className='list-hover' key={index} onClick={() => {
                                            this.noticeBindClick(index)
                                        }}>
                                            <div style={{display: 'flex', textAlign: 'center'}}>
                                                <span>{item.url === '' ? (<NoticeTextIcon style={{
                                                    marginRight: '12px',
                                                    color: '#52c41a',
                                                    fontSize: '2em'
                                                }}/>) : (<NoticeLinkIcon style={{
                                                    marginRight: '6px',
                                                    color: '#1790ff',
                                                    fontSize: '1.6em'
                                                }}/>)}</span>
                                                <div style={{fontSize: '1.0em'}}>{item.content}</div>
                                            </div>
                                            <div style={{float: 'left'}}>
                                                <div style={{
                                                    marginLeft: '28px',
                                                    fontSize: '0.8em',
                                                    float: 'left'
                                                }}>{item.content.time}</div>
                                                <div style={{
                                                    marginLeft: '40px',
                                                    fontSize: '0.9em',
                                                    float: 'right'
                                                }}>{this.getDateStr(item.content.time)}</div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return ''
                                }
                            })
                        )}
                    </div>
                </Drawer>

            </Header>
        );
    }
}

export default withRouter(LayoutHead)