import {
    Button,
    Card,
    Checkbox,
    Col,
    Descriptions,
    Form,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Table
} from "antd";
import React, {useEffect, useState} from "react";
import {GetStatusName} from "../../../../utils/define";
import {
    ajaxAdminConfigGetGroupList, ajaxAdminConfigGetGroupMessageInfo, ajaxAdminConfigGetGroupMessageSave,
    ajaxAdminUserSetTelegramGroupSend
} from "../../../../api";
import Editor from "../../../../components/editor";

const formSendRef = React.createRef()
const formTimeRef = React.createRef()
export default function TelegramGroup(){
    const [listLoading, setListLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visibleInfo, setVisibleInfo] = useState({});
    const [visibleTime, setVisibleTime] = useState(false);

    const [groupList, setGroupList] = useState([]);

    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
    }

    const fetch = async (params = {}) => {
        setListLoading(true)
        const result = await ajaxAdminConfigGetGroupList(params)
        if (result.code === 1) {
            setListData(result.data.list)
        }
        setListLoading(false)
    }

    const time = () => {
        fetchGroup()
        fetchInfo()
        setVisibleTime(true)
    }
    const fetchInfo = async () => {
        const result = await ajaxAdminConfigGetGroupMessageInfo()
        if (result.code === 1) {
            formTimeRef.current.setFieldsValue(result.data)
        }
    }

    const fetchGroup = async () => {
        const result = await ajaxAdminConfigGetGroupList()
        if (result.code === 1) {
            setGroupList(result.data.list)
        }
    }

    const TimeSubmit = async(values) => {
        values.group = JSON.stringify(values.group)
        values.inline_button = JSON.stringify(values.inline_button)
        const res = await ajaxAdminConfigGetGroupMessageSave(values)
        if (res.code === 1) {
            message.success(res.msg)
        }
    }

    const SendSubmit = async (values) => {
        const res = await ajaxAdminUserSetTelegramGroupSend(values)
        if (res.code === 1) {
            message.success(res.msg)
        }
    }

    const columns = [
        {
            title: '群组 ID',
            dataIndex: 'chat_id',
        },  {
            title: '群组名称',
            dataIndex: 'chat_name',
        },  {
            title: '状态',
            dataIndex: 'status',
            render: (text, item) => {
                return (
                    <> {GetStatusName(item.status)} </>
                );
            },
        },   {
            title: '添加时间',
            dataIndex: 'created_at'
        },  {
            title: '更新时间',
            dataIndex: 'updated_at'
        }, {
            title: "操作",
            render: (text, item) => {
                return (
                    <Space>
                        <Button type={'link'} onClick={() => {
                            formSendRef.current.setFieldsValue(item)
                            setVisibleInfo(item)
                            setVisible(true)
                        }}>发送消息</Button>
                    </Space>
                );
            },
        }]

    return <>
        <Card title="列表" extra={<Button style={{width:240}} onClick={()=>{time(true)}} type="primary">定时消息</Button>}>
            <Table
                columns={columns}
                rowKey="id"
                pageSize={999}
                dataSource={listData}
                loading={listLoading}
            />
        </Card>
        <Modal open={visible} width="70%" title={"发送消息"} forceRender={true} footer={null} onCancel={() => {
            setVisible(false)
        }}>
            <Descriptions title="" size="small">
                <Descriptions.Item label="群组ID">{visibleInfo.chat_id}</Descriptions.Item>
                <Descriptions.Item label="群组名称">{visibleInfo.chat_name}</Descriptions.Item>
            </Descriptions>
            <br/><br/>
            <Form onFinish={SendSubmit} labelAlign="right" ref={formSendRef}>
                <Form.Item name="chat_id" hidden={true}><Input /></Form.Item>
                <Form.Item label="消息内容" name="content">
                    <Editor onChange={(eee)=>{
                        formSendRef.current.setFieldsValue({content:eee})
                    }}/>
                </Form.Item>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button style={{width:240}} type="primary" htmlType="submit">
                                发送
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>

        <Modal open={visibleTime} width="70%" title={"定时消息"} forceRender={true} footer={null} onCancel={() => {
            setVisibleTime(false)
        }}>
            <Form onFinish={TimeSubmit} labelAlign="right" ref={formTimeRef}>
                <Form.Item label="播报群组：" name="group" rules={[{required: true, message: '不能为空'}]}>
                    <Checkbox.Group style={{ width: '100%' }}>
                        {groupList?groupList.map((item, _) => {
                            return  <Checkbox value={item.chat_id}>{item.chat_name}</Checkbox>
                        }):""}
                    </Checkbox.Group>
                </Form.Item>


                <Form.Item label="播报频率：" name="rate" rules={[{required: true, message: '不能为空'}]}>
                    <Select
                        defaultValue={2}
                        style={{ width: 120 }}
                        allowClear
                        options={[
                            {value: 0, label: '5',},
                            {value: 1, label: '10分钟',},
                            {value: 2, label: '1小时',},
                            {value: 3, label: '12小时',},
                            {value: 4, label: '24小时',},
                        ]}
                    />
                </Form.Item>

                <Form.Item label="消息内容" name="content" rules={[{required: true, message: '不能为空'}]}>
                    <Editor onChange={(eee)=>{
                        formTimeRef.current.setFieldsValue({content:eee})
                    }}/>
                </Form.Item>

                <Form.Item label="状态" name="status" rules={[{required: true, message: '不能为空'}]}>
                    <Radio.Group value={1}>
                        <Radio value={0}>关闭</Radio>
                        <Radio value={1}>开启</Radio>
                    </Radio.Group>
                </Form.Item>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button style={{width:240}} type="primary" htmlType="submit">
                                发送
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}