import React, {useEffect, useState} from "react";
import {Button, Card, Col, Descriptions, Form, Input, message, Modal, Row, Space, Table} from "antd";
import {ajaxAdminUserGetList, ajaxAdminUserSetTelegramSend, ajaxAdminUserSetTelegramSends} from "../../../../api";
import Editor from "../../../../components/editor";
import Recharge from "./recharge";
import Balance from "./balance";
import storageUtils from "../../../../utils/storage";

const formRef = React.createRef()
const formSendRef = React.createRef()
const formSendRefQun = React.createRef()

export default function UserList(props){
    const [listData, setListData] = useState([]);
    const [listPagination, setListPagination] = useState({showSizeChanger: false});
    const [listLoading, setListLoading] = useState(false);
    const [soSO,setSoSO] = useState({});
    const [visible, setVisible] = useState(false);
    const [visibleInfo, setVisibleInfo] = useState({});
    const [visibleRecharge, setVisibleRecharge] = useState(false);
    const [visibleBalance, setVisibleBalance] = useState(false);

    const [visibleQun, setVisibleQun] = useState(false);
    const [qun, setQun] = useState(false);

    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listPagination.pagination?.current]); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
    }

    const fetch = async (params = {}) => {
        setListLoading(true)
        params.page = params.page ? params.page : 1
        const result = await ajaxAdminUserGetList(params)
        if (result.code === 1) {
            const listPaginationNew = { ...listPagination }
            listPaginationNew.total = result.data.total
            listPaginationNew.pageSize = result.data.limit
            listPaginationNew.showTotal = total => `总计 ${total} 条`
            listPaginationNew.current = params.page

            const listData = result.data.list
            setListData(listData)
            setListLoading(false)
            setSoSO(params);
            setListPagination(listPaginationNew)
        }
    }

    const SoSubmit = (values) => {
        values.page = 1
        fetch(values).then()
    }

    const handleTableChange = (pagination) =>{
        soSO.page = pagination.current
        fetch(soSO).then()
    }

    const SendSubmit = async (values) => {
        console.log(values)
        const res = await ajaxAdminUserSetTelegramSend(values)
        if (res.code === 1) {
            message.success(res.msg)
        }
    }

    const SendSubmitQun = async (values) => {
        const res = await ajaxAdminUserSetTelegramSends(values)
        setQun(res)
    }

    const columns = [
         {
            title: '用户ID',
            dataIndex: 'telegram_id',
        }, {
            title: '用户名称',
            dataIndex: 'user_name',
        }, {
            title: '用户昵称',
            dataIndex: 'first_name',
        }, {
            title: 'TRX',
            dataIndex: 'trx',
        },   {
            title: 'USDT',
            dataIndex: 'usdt'
        },  {
            title: '添加时间',
            dataIndex: 'created_at'
        },  {
            title: '更新时间',
            dataIndex: 'updated_at'
        }, {
            title: "操作",
            render: (text, item) => {
                return (
                    <Space>
                        <Button type={'link'} onClick={() => {
                            setVisibleInfo(item);
                            formSendRef.current.setFieldsValue({telegram_id:item.telegram_id});
                            setVisible(true)}
                        }>发送消息</Button>


                        <Button type={'link'} onClick={() => {
                            setVisibleInfo(item);
                            setVisibleRecharge(true)}
                        }>充值</Button>

                        <Button type={'link'} onClick={() => {
                            setVisibleInfo(item);
                            setVisibleBalance(true)}
                        }>余额记录</Button>
                    </Space>
                );
            },
        }]
    return <>
        <Card >
            <Form onFinish={SoSubmit} labelAlign="right" ref={formRef}>
                <Row gutter={[8, 0]}>
                    <Col span={8}>
                        <Form.Item label="TG用户名" name="user_name">
                            <Input placeholder={'请输入姓名'} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="TG用户Id" name="telegram_id">
                            <Input placeholder={'请输入ID'} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button htmlType="button" style={{ marginBottom: 0, marginLeft: 5 }} onClick={() => {
                                formRef.current.resetFields();
                            }}>
                                重置
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
        <br/>
        <Card title="列表" extra={<Button style={{width:240}} onClick={()=>{setVisibleQun(true)}} type="primary">群发消息</Button>}>
            <Table
                columns={columns}
                rowKey="id"
                pageSize={15}
                dataSource={listData}
                pagination={listPagination}
                loading={listLoading}
                onChange={handleTableChange}
            />
        </Card>

        <Recharge visible={visibleRecharge} setVisible={(e)=>{
            setVisibleRecharge(e)
        }} init={()=>initFetch()} visibleInfo={visibleInfo}/>

        <Balance visible={visibleBalance} setVisible={(e)=>{
            setVisibleBalance(e)
        }} visibleInfo={visibleInfo}/>

        <Modal open={visible} width="70%" title={"发送消息"} forceRender={true} footer={null} onCancel={() => {
            setVisible(false)
        }}>
            <Descriptions title="" size="small">
                <Descriptions.Item label="TG用户ID">{visibleInfo.telegram_id}</Descriptions.Item>
                <Descriptions.Item label="TG用户名称">{visibleInfo.user_name}</Descriptions.Item>
            </Descriptions>
            <br/><br/>
            <Form onFinish={SendSubmit} labelAlign="right" ref={formSendRef}>
                <Form.Item name="telegram_id" hidden={true}><Input /></Form.Item>
                <Form.Item label="消息内容" name="content">
                    <Editor onChange={(eee)=>{
                        formSendRef.current.setFieldsValue({content:eee})
                    }}/>
                </Form.Item>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button style={{width:240}} type="primary" htmlType="submit">
                                发送
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>


        <Modal open={visibleQun} width="70%" title={"群发消息"} forceRender={true} footer={null} onCancel={() => {
            setVisibleQun(false)
        }}>
            <Form onFinish={SendSubmitQun} labelAlign="right" target="target1" ref={formSendRefQun}>
                <Form.Item label="消息内容" name="content">
                    <Editor onChange={(eee) => {
                        formSendRefQun.current.setFieldsValue({content: eee})
                    }}/>
                </Form.Item>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button style={{width: 240}} type="primary" htmlType="submit">
                                发送
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <div dangerouslySetInnerHTML={{__html: qun}}/>
        </Modal>
    </>
}