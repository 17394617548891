import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, message, Modal, Row, Space, Table} from "antd";
import {
    ajaxAdminUserGetUserFilterList,
    ajaxAdminUserSetUserFilter
} from "../../../../api";
import {GetValidateAddress} from "../../../../utils/define";

const formRef = React.createRef()
const formSendRef = React.createRef()

export default function UserFilter(props){
    const [listData, setListData] = useState([]);
    const [listPagination, setListPagination] = useState({showSizeChanger: false});
    const [listLoading, setListLoading] = useState(false);
    const [soSO,setSoSO] = useState({});
    const [visible, setVisible] = useState(false);


    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listPagination.pagination?.current]); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
    }

    const fetch = async (params = {}) => {
        setListLoading(true)
        params.page = params.page ? params.page : 1
        const result = await ajaxAdminUserGetUserFilterList(params)
        if (result.code === 1) {
            const listPaginationNew = { ...listPagination }
            listPaginationNew.total = result.data.total
            listPaginationNew.pageSize = result.data.limit
            listPaginationNew.showTotal = total => `总计 ${total} 条`
            listPaginationNew.current = params.page

            const listData = result.data.list
            setListData(listData)
            setListLoading(false)
            setSoSO(params);
            setListPagination(listPaginationNew)
        }
    }

    const SoSubmit = (values) => {
        values.page = 1
        fetch(values).then()
    }

    const handleTableChange = (pagination) =>{
        soSO.page = pagination.current
        fetch(soSO).then()
    }

    const SendSubmit = async (values) => {
        console.log(values)
        const res = await ajaxAdminUserSetUserFilter(values)
        if (res.code === 1) {
            message.success(res.msg)
            setVisible(false);
        }
    }

    const columns = [
         {
            title: '编号',
            dataIndex: 'id',
        }, {
            title: '地址',
            dataIndex: 'address',
        },  {
            title: '添加时间',
            dataIndex: 'created_at'
        },  {
            title: '更新时间',
            dataIndex: 'updated_at'
        }, {
            title: "操作",
            render: (text, item) => {
                return (
                    <Space>
                        <Button type={'link'} onClick={() => {
                            //setOperate("编辑")
                            formSendRef.current.setFieldsValue(item)
                            setVisible(true)
                        }}>编辑</Button>
                    </Space>
                );
            },
        }]
    return <>
        <Card >
            <Form onFinish={SoSubmit} labelAlign="right" ref={formRef}>
                <Row gutter={[8, 0]}>
                    <Col span={8}>
                        <Form.Item label="地址" name="address">
                            <Input placeholder={'请输入地址'} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button htmlType="button" style={{ marginBottom: 0, marginLeft: 5 }} onClick={() => {
                                formRef.current.resetFields();
                            }}>
                                重置
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
        <br/>
        <Card title="列表">
            <Button type="primary" style={{marginBottom: 15, width: 160}} onClick={()=>{
                formSendRef.current.resetFields()
                setVisible(true);
            }}> 添加 </Button>
            <Table
                columns={columns}
                rowKey="id"
                pageSize={15}
                dataSource={listData}
                pagination={listPagination}
                loading={listLoading}
                onChange={handleTableChange}
            />
        </Card>



        <Modal open={visible} width="70%" title={"输入地址"} forceRender={true} footer={null} onCancel={() => {
            setVisible(false)
        }}>
            <Form onFinish={SendSubmit} labelAlign="right" ref={formSendRef}>
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Form.Item label="地址" name="address" rules={GetValidateAddress()}>
                    <Input placeholder={'地址不能为空'}/>
                </Form.Item>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button style={{width:240}} type="primary" htmlType="submit">
                                保存
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}